import { Script } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { JobSearchJourneyPage } from './types';

const widgetNameForPage: { [page in JobSearchJourneyPage]: string | null } = {
  [JobSearchJourneyPage.SEARCH_FORM]: 'SearchCriteriaWidget',
  [JobSearchJourneyPage.LIST]: 'JobsListWidget',
  [JobSearchJourneyPage.JOB_DESCRIPTION]: 'JobDetailWidget',
  [JobSearchJourneyPage.SEND_TO_FRIEND]: 'SendJobToAFriendWidget',
  [JobSearchJourneyPage.ALERT_SIGN_UP]: 'SearchAgentWidget',
  [JobSearchJourneyPage.APPLY]: null, // apply does not have the same top level widget
};

type JobSearchScriptsProps = {
  page: JobSearchJourneyPage;
};

const JobSearchScripts: React.FC<JobSearchScriptsProps> = ({ page }) => {
  const [loaded, setLoaded] = useState(false);
  const [talentLinkLoaded, setTalentLinkLoaded] = useState(false);

  /*
    For the scripts here
    1. `require` is needed by the following two scripts
    2. the `talentportal-widgets` add the relevant form components to the page

    The useEffect then will reload the relevant widget 
    This is neccessary as the script from 2 will only load once, so if you navigate around the site client-side (e.g. clicking links in the header)
    it will not trigger script 2 to reload when rereaching this page.

    Also, script 2 cannot be reloaded in the same window, as it imports History.js, so will complain that History.js is already loaded.
  */

  useEffect(() => {
    const widgetName = widgetNameForPage[page];
    if (talentLinkLoaded && typeof window.require !== 'undefined' && widgetName) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window.require as any)(
        ['WidgetLoader'],
        (WidgetLoader: { load: (s: string) => void }) => {
          WidgetLoader.load(widgetName);
        }
      );
    }
  }, [talentLinkLoaded, page]);

  return (
    <>
      <Script src="/careers/require.js" onLoad={() => setLoaded(true)} />
      {loaded && (
        <Script
          src="https://emea3.recruitmentplatform.com/apply-app/static/talentportal/release/latest/talentportal-widgets.js"
          data-lumesse-site-tech-id="Q0JFK026203F3VBQB7968F6Z6"
          data-lumesse-fo-host="emea3.recruitmentplatform.com"
          onLoad={() => setTalentLinkLoaded(true)}
        />
      )}
    </>
  );
};

export default React.memo(JobSearchScripts);
