import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { fonts } from '@rsa-digital/evo-shared-components/theme/RSA/fonts';
import styled from 'styled-components';
import talentLinkGlobalStyles from '../styles';

const JobSendToFriendContainer = styled.div`
  ${talentLinkGlobalStyles};

  #talentSendToAFriendForm {
    padding-top: ${spacing(4)};
  }
  #lumesseSendJobToAFriendWidget {
    #talentSendToAFriendFormLegend {
      ${fonts.headingSmall};
      margin-bottom: ${spacing(4)};
    }

    button {
      margin-top: ${spacing(4)};
      width: fit-content;
    }

    .alert-success {
      a {
        word-break: break-all;
      }
    }
  }
`;

export default JobSendToFriendContainer;
