enum JobSearchJourneyPage {
  SEARCH_FORM,
  LIST,
  JOB_DESCRIPTION,
  SEND_TO_FRIEND,
  ALERT_SIGN_UP,
  APPLY,
}

// default export would give this an ambigious name / break the general naming convention of types
// eslint-disable-next-line import/prefer-default-export
export { JobSearchJourneyPage };
