import React from 'react';
import JobSendToFriendContainer from './styles';

const JobSendToFriend: React.FC = () => (
  <JobSendToFriendContainer>
    <div id="send2friend" className="PSOFOShop container">
      <div className="row">
        <div className="twelve columns">
          <div id="lumesseSendJobToAFriendWidget" />
        </div>
      </div>
    </div>
  </JobSendToFriendContainer>
);

export default React.memo(JobSendToFriend);
