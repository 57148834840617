import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import {
  colors,
  themeSpecificColors,
} from '@rsa-digital/evo-shared-components/theme/RSA/colors';
import { fonts } from '@rsa-digital/evo-shared-components/theme/RSA/fonts';
import { css } from 'styled-components';

const button = css`
  border-radius: 32px;
  border: 2px solid transparent;
  padding: ${spacing(2)} ${spacing(3)};
  line-height: 32px;
  text-decoration: none;

  &:hover {
    outline: 4px solid ${themeSpecificColors.core01_60};
    text-decoration: underline;
  }

  &:focus-visible {
    outline: 4px solid ${themeSpecificColors.core01_60};
    outline-offset: 2px;
    text-decoration: underline;
  }

  &:active {
    outline: 10px solid ${themeSpecificColors.core01_60};
    outline-offset: 2px;
    text-decoration: underline;
  }
`;

export const primaryButton = css`
  ${button}
  background-color: ${colors.core01};
  color: white;

`;

export const secondaryButton = css`
  ${button}
  background-color: white;
  color: ${colors.core01};
  border-color: ${colors.core01};

  &:focus-visible {
    border-color: ${colors.core01};
  }
`;

const talentLinkGlobalStyles = css`
  /* & below used to ensure higher priority than styles imported by JS */
  &&&&&&& {
    fieldset {
      border: none;
      padding: 0;
      margin: 0;
    }

    dt,
    dd {
      margin: 0;
    }

    & {
      ${fonts.paragraph};
    }

    h1 {
      ${fonts.headingLarge}
    }

    h2 {
      ${fonts.headingXSmall}
    }

    input,
    select {
      width: 100%;
      padding: ${spacing(2)};
    }

    .form-group {
      display: flex;
      flex-direction: column;
      margin-bottom: ${spacing(4)};

      .control-label {
        font-weight: 500;
      }

      label {
        margin-bottom: ${spacing(1)};
      }
    }

    button {
      ${primaryButton}
    }

    .form-control {
      border-radius: 0;
      border: 1px solid ${colors.neutral03};
    }

    .radio-inline,
    .radio {
      margin-right: ${spacing(2)};
      display: inline-flex;
      align-items: center;

      input[type='radio'] {
        width: 32px;
        height: 32px;
        margin-right: ${spacing(1)};
        accent-color: ${colors.core01};
      }
    }

    input:active,
    select:active,
    textarea:active {
      border-color: ${colors.core01};
      outline: 1px solid ${colors.core01};
    }

    input:focus-visible,
    select:focus-visible,
    textarea:focus-visible {
      border-color: ${colors.core01};
      border-width: 1px;
      outline: 4px solid ${themeSpecificColors.core01_60};
      outline-offset: 1px;
    }

    .lumesse-error-message {
      color: ${colors.notificationError};
    }

    .alert {
      ${fonts.headingXSmall};

      padding: ${spacing(2)};
      border-width: 1px;
      border-style: solid;

      ul {
        ${fonts.paragraph};
        margin-top: ${spacing(1)};
        padding-left: ${spacing(2.5)};

        li {
          margin-top: ${spacing(2)};

          a {
            color: unset;
            text-decoration: none;
          }
        }
      }
    }

    .alert-danger {
      background: ${colors.notificationErrorLight};
      border-color: ${colors.notificationError};
    }

    .alert-success {
      background: ${colors.notificationSuccessLight};
      border-color: ${colors.notificationSuccess};
    }

    form {
      max-width: 544px;
    }
  }
`;

export default talentLinkGlobalStyles;
